<template>
  <div class="space-root h-100">
    <b-spinner 
      v-if="isLoading" 
      class="d-block mx-auto text-primary my-2" 
    />
    <component
      v-else 
      :is="`layout-${layout}`"
      @error="handleLayoutError"
    >
      <router-view />
    </component>
  </div>
</template>

<script>
import Vue from 'vue';
import { ENABLED_APPS_ACTIONS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { getImageResource } from '@/@core/utils/image-utils';
import { NOTIFICATIONS_ACTIONS } from '@/store/notifications/notifications-store-constants';
import FaviconPlaceholder from '@/assets/images/placeholders/light/nectios-favicon.png';
import Service from '@/config/service-identifiers';
import { selectLangFromCommunity } from '@/@core/utils/collective';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { startNotifications, sendMixpanelNotification } from '@/@core/utils/notifications/notifications';

import { mapGetters } from 'vuex';

// Vue Meta
import VueMeta from 'vue-meta'
Vue.use(VueMeta);

export default {
  name: 'SpaceRoot',

  metaInfo: {
    meta: [{
      vmid: 'google-site-verification',
      name: 'google-site-verification',
      content: '???',
    }]
  },

  components: {
    LayoutHorizontal: () => import('@/layouts/horizontal/LayoutHorizontal.vue' /* webpackChunkName: "LayoutHorizontal" */)
      .catch(error => {
        console.error('Failed to load LayoutHorizontal component:', error);
        return {
          template: '<div class="error-fallback p-3"><p>Failed to load layout. Please try refreshing the page.</p></div>'
        };
      }),
    LayoutVertical: () => import('@/layouts/vertical/LayoutVertical.vue' /* webpackChunkName: "LayoutVertical" */)
      .catch(error => {
        console.error('Failed to load LayoutVertical component:', error);
        return {
          template: '<div class="error-fallback p-3"><p>Failed to load layout. Please try refreshing the page.</p></div>'
        };
      }),
  },

  data() {
    return {
      isLoading: true,
      user: {},
    };
  },
  computed: {
    ...mapGetters([
      'loggedUser',
      'loggedMember',
      'apps',
      'communityinitialized',
      'collective',
      'mainCollective',
      'currentCollective',
      'collectiveFavicon',
      'integrations',
    ]),
    communitySlug() {
      const slug = this.$route.params.communityId;
      // console.log('SpaceRoot - communitySlug', slug);
      
      // Normalize slug if it's an object
      let normalizedSlug = slug;
      if (typeof slug === 'object' && slug !== null) {
        console.warn('SpaceRoot - detected object instead of string for communitySlug', slug);
        normalizedSlug = slug.slug || slug.id || '';
      }
      
      // Thanks to our paramNormalizer guard, slug should always be a string
      // But ensure it's a string for safety
      normalizedSlug = String(normalizedSlug || '');
      
      if (!normalizedSlug || normalizedSlug === 'undefined' || normalizedSlug === 'null' || normalizedSlug === 'error') {
        console.error('SpaceRoot - Invalid communitySlug after normalization');
        return '';
      }
      
      return normalizedSlug;
    },
    showChatbot() {
      return this.mainSpace?.showChatbot;
    },
    chatbotCustom() {
      return this.mainSpace?.customChatbot;
    },
    documentTitle() {
      return this.mainSpace?.name;
    },
    faviconURL() {
      return getImageResource(this.collectiveFavicon) || FaviconPlaceholder;
    },
    mainSpace() {
      return this.mainCollective.key ? this.mainCollective : this.currentCollective;
    },
    isNexos() {
      try {
        return process.env.VUE_APP_CUSTOMER === 'nexos' || 
          (this.mainSpace && this.mainSpace.key === 'f3166db0-b635-11ef-805d-f79ce25412de');
      } catch(e) {
        console.warn('Error in isNexos computed property:', e);
        return false;
      }
    },
    layout() {
      try {
        if (this.isNexos) {
          return 'vertical';
        }
        if (this.$route.path.includes('/myself')) {
          return 'vertical';
        }
        if (this.currentCollective?.menu?.layout) {
          return this.currentCollective.menu.layout;
        }
        if (this.$store.state.appConfig?.layout?.type) {
          return this.$store.state.appConfig.layout.type;
        }
        return 'horizontal'; // Default fallback layout
      } catch(e) {
        console.warn('Error in layout computed property:', e);
        return 'horizontal'; // Safe fallback
      }
    },
  },

  metaInfo() {
    if(this.communitySlug === "terrassaindustria"){
      // <meta name="google-site-verification" content="" />
      return {
        meta: [{
          vmid: 'google-site-verification',
          name: 'google-site-verification',
          content: 'Rwg6wwWtBFZ8SIYUM3uDrzAkLc-W_hgukbI3ZEqGxCU',
        }]
      }
    }
  },

  async created() {
    // console.log('SpaceRoot - created()')

    this.isLoading = true;

    // console.log('SpaceRoot - this.communitySlug', this.communitySlug);

    const typeOfCommunitySlug = typeof this.communitySlug;
    if (!this.communitySlug) {
      // console.log('No valid community slug found, redirecting to main root...');
      this.$router.push('/');
      return;
    }

    if (this.communitySlug === 'error' || this.communitySlug === 'undefined') {
      // console.log('SpaceRoot - Error is not a valid community slug, redirecting to main root... ');
      this.$router.push('/');
      return;
    }

    // console.log('Welcome, you are trying to access to a space with slug:', this.communitySlug);
    // console.log('this.loggedUser', this.loggedUser);
    // console.log('this.loggedMember', this.loggedMember);
    // console.log('this.collective', this.collective);
    // console.log('this.currentCollective', this.currentCollective);

    if(!this.loggedUser.id) {
      // console.log('Your user is not initialized...');
      if(this.$route.name === 'welcome') {
        // console.log('You are in welcome...');
      }
    }

    //console.log('SpaceRoot.vue (this.mainCollective) ', this.mainCollective)

    // Get Apps
    if (typeof this.apps === 'object' && !this.apps.apps) {
      // console.log('Getting space apps...');
      await this.$store.dispatch('getItems', {
        itemType: 'apps',
        page: 1,
        communitySlug: this.communitySlug || null,
      });
    }
    
    // console.log('communityinitialized - start', this.communityinitialized);
    // check if the current community initialized is the same that we have in store
    const currentSpaceIsInitialized = this.communitySlug === this.communityinitialized;
    if (!this.communityinitialized || !currentSpaceIsInitialized) {
      // console.log('SpaceRoot - dispatch initCommunity - communityinitialized is not initialized, initializing community');
      await this.initCommunity(this.communitySlug);
      // console.log('2 SpaceRoot.vue', this.apps)
    }

    // console.log('SpaceRoot - this.currentCollective', this.currentCollective);

    if(!this.currentCollective) {
      // get current collective
      // console.log('SpaceRoot - dispatch initCurrentCommunity - currentCollective is not initialized, initializing community');
      await this.$store.dispatch('initCurrentCommunity', {
        communitySlug: this.communitySlug,
      });
    }

    // check if is member of this space
    // console.log('SpaceRoot - this.$route.name', this.$route.name);
    if (this.$route.name === 'onboarding-form') {
      // console.log('Accessing onboarding form, skipping membership check');
    } else if(this.$route.name === 'onboarding-plans') {
      // console.log('Accessing onboarding plans, skipping membership check');
    } else if(this.currentCollective.isSection) {
      // TODO: Check if is member of the parent
      // console.log('This is a section, you can access');
    } else if(this.currentCollective.isMember && this.currentCollective.status === 3) {
      // console.log('You are a member of this space');
    } else if (this.currentCollective.isStaff) {
      // console.log('You are a staff of this space');
    } else {
      // console.log('You are not a member nor a staff of this space. Redirecting you to the welcome page...');
      this.$router.push({
        name: 'welcome',
        params: {
          communityId: this.currentCollective?.slug,
        },
      });
    }

    this.isLoading = false; // to render the FE

    // console.log('communityinitialized - end', this.communityinitialized);

    // const memberKey = this.loggedMember.key;
    // const communitySlug = this.communitySlug;
    // console.log('SpaceRoot - startNotifications');
    await startNotifications({
      memberKey: this.loggedMember?.key,
      communitySlug: this.communitySlug,
    });
    // console.log('SpaceRoot - sendMixpanelNotification');
    await sendMixpanelNotification('member-in-community');

    // this.isLoading = false;

    this.$router.afterEach((to, from) => {
      // console.log('SpaceRoot - addAnalytic');
      this.$store.dispatch('addAnalytic', {
        morphType: 'page',
        action: 'enter-page',
        content: to.path,
      });

      // console.log('SpaceRoot - selectLangFromCommunity');
      if (this.mainSpace) {
        // console.log('is a mains space, selectLangFromCommunity');
        selectLangFromCommunity(this.mainSpace);
      }
      // console.log('SpaceRoot - afterEach done');
    });

    // console.log('SpaceRoot - initializeServiceWorker');
    await this.$store.dispatch(NOTIFICATIONS_ACTIONS.initializeServiceWorker);

    // console.log('FINAL SpaceRoot.vue - created()')
  },

  mounted() {
    //console.log('SpaceRoot mounted...');
    if (!this.$store.state.copernicData.mountedSocket[this.communitySlug]) {
      this.$socket.emit('joinAnalytics', this.communitySlug);
      this.$socket.emit('joinCommunity', this.communitySlug);
      this.$store.state.copernicData.mountedSocket[this.communitySlug] = true;
    }
    if (!this.$store.state.copernicData.mountedSocket.global) {
      this.Notifications();
      this.$store.state.copernicData.mountedSocket.global = true;
    }
  },
  sockets: {
    connect(data) {
      this.$socket.emit('joinAnalytics', this.communitySlug);
      this.$socket.emit('joinCommunity', this.communitySlug);
    },
    notificationCount(params) {
      this.$store.dispatch(NOTIFICATIONS_ACTIONS.unreadCount, params);
    },
    newData(data) {
      if (data.communitySlug === this.communitySlug) {
        this.$store.commit('ADD_ITEM', data);
      }
    },
    updateData(data) {
      if (data.communitySlug === this.communitySlug) {
        this.$store.dispatch('UpdateFromSocket', data);
      }
    }
  },
  methods: {
    Notifications() {
      // do work
      /* this.$socket.emit("receiveNotifications", this.$route.params.communityId);

      //queue more work
      setTimeout(this.Notifications, 15000); */
    },
    async initCommunity(communitySlug) {
      // console.log('SPACE ROOT initCommunity - original slug:', communitySlug);
      
      // Enhanced validation and normalization
      if (!communitySlug) {
        console.error('Empty community slug provided to initCommunity');
        return this.$router.push({ name: 'error', params: { error: '404' } });
      }
      
      // Handle object slug and ensure string type
      let normalizedSlug = communitySlug;
      if (typeof communitySlug === 'object' && communitySlug !== null) {
        // console.log('Normalizing object communitySlug in SpaceRoot:', communitySlug);
        normalizedSlug = communitySlug.slug || communitySlug.id || '';
      }
      
      // Ensure it's a valid string
      normalizedSlug = String(normalizedSlug);
      if (!normalizedSlug || normalizedSlug === 'undefined' || normalizedSlug === 'null' || normalizedSlug === 'error') {
        console.error('Invalid community slug after normalization');
        return this.$router.push({ name: 'error', params: { error: '404' } });
      }
      
      try {
        // console.log('Initializing space with slug:', normalizedSlug);
        await this.$store.dispatch('initCurrentCommunity', {
          communitySlug: normalizedSlug,
        });

        selectLangFromCommunity(this.mainSpace);

        // Page title
        document.title = this.translate(this.documentTitle);

        // console.log('SPACE ROOT initCommunity - dispatch fetchEnabledApps and layout and elements');
        const action = ENABLED_APPS_ACTIONS.fetchEnabledApps; 
        // console.log('SPACE ROOT initCommunity - dispatch', action);
        await this.$store.dispatch(action);
        // console.log('SPACE ROOT dispatch actions - END');

        const links = document.querySelectorAll("link[rel~='icon']");
        for (let index = 0; index < links.length; index += 1) {
          const link = links[index];
          link.href = this.faviconURL;
        }

        // console.log('SPACE ROOT initCommunity - getIntegrations');
        const integrations = await this.getIntegrations();

        let tagManager = null;
        let analytics = null;
        let hotjarID = null;
        if (integrations.length > 0) {
          // console.log('integrations', integrations);

          tagManager = integrations.filter(
            (item) => item.key === 'google-tag-manager',
          )[0]?.customizations?.['Google-Tag-Manager-Key']; // terrassaindustria

          analytics = integrations.filter(
            (item) => item.key === 'google-analytics',
          )[0]?.customizations?.['Google-Analytics-Measurement-ID'];  //conexion-espana y terrassaindustria

          hotjarID = integrations.filter(
            (item) => item.key === 'hotjar',
          )[0]?.customizations?.['Hotjat-site-ID']; // eunoia
        }
        // console.log('Tag manager:', tagManager);
        // console.log('analytics:', analytics);
        // console.log('hotjarID:', hotjarID);

        // Google Tag Manager: The Main community tag must be in the entire hierarchy
        if (tagManager) {
          // console.log('This community has G tag manager with Tag:', tagManager);
          // Add GTM script to head
          const googleTag = document.createElement('script');
          googleTag.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-${tagManager}');`;
          document.head.appendChild(googleTag);

          // Add GTM noscript iframe to body
          const noscript = document.createElement('noscript');
          const iframe = document.createElement('iframe');
          iframe.src = `https://www.googletagmanager.com/ns.html?id=GTM-${tagManager}`;
          iframe.height = "0";
          iframe.width = "0";
          iframe.style.display = "none";
          iframe.style.visibility = "hidden";
          noscript.appendChild(iframe);
          document.body.appendChild(noscript);
        }

        // Google Analytics: The Main community tag must be in the entire hierarchy
        if (analytics) {
          // console.log('This community has Google Analytics with Tag:', analytics);
          const googleTag = document.createElement('script');
          googleTag.src = `https://www.googletagmanager.com/gtag/js?id=${analytics}`;
          document.head.appendChild(googleTag);
          const googleTagAnalytics = document.createElement('script');
          googleTagAnalytics.innerHTML = `window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${analytics}');`;
          document.head.appendChild(googleTagAnalytics);
        }

        // HOTJAR
        if(hotjarID){
          // console.log('This community has Hotjar with ID:', hotjarID);
          const hotjar = document.createElement("script");
          hotjar.innerHTML = `
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:${hotjarID},hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
          document.head.appendChild(hotjar);
        }

        // Head metadata:
        const localSpaceMetadata = this.currentCollective?.headMeta;
        const mainSpaceMetadata = this.mainCollective?.headMeta;
        const metadata = localSpaceMetadata ?? mainSpaceMetadata;
        document.getElementsByName('author').content = metadata?.name_author;
        document.getElementsByName('description').content = metadata?.name_description;
        document.getElementsByName('keywords').content = metadata?.name_keywords;
        document.getElementsByName('twitter:title').content = metadata?.name_twitter_title;
        document.getElementsByName('twitter:description').content = metadata?.name_twitter_description;
        document.getElementById('ogtitle').content = metadata?.property_og_title;
        document.getElementById('ogdescription').content = metadata?.property_og_description;

        // Chatbot
        const chatBot = document.createElement('script');
        // Nectios Support Chat only in Backoffice
        if (this.$route.path.includes('/backoffice')) {
          // chatBot.innerText = 'window.$crisp=[];window.CRISP_WEBSITE_ID="a1aa36a2-c0e5-44a4-bc75-c5f95f1af801";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();';
          // Crisp.user.setEmail(this.$store.getters.loggedUser.email);
        } else {
          const showChatbot = this.mainSpace?.showChatbot ?? null;
          const customChatbot = this.mainSpace?.customChatbot;
          if (showChatbot && customChatbot && !customChatbot.includes('window.')) {
            // Customer's chatbot
            if (showChatbot === 2) { // Crisp
              chatBot.innerText = `window.$crisp=[];window.CRISP_WEBSITE_ID="${customChatbot}";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`;
            } else if (showChatbot === 3) { // Hubspot
              chatBot.type = 'text/javascript';
              chatBot.id = 'hs-script-loader';
              chatBot.async = true;
              chatBot.defer = true;
              chatBot.src = `//js-eu1.hs-scripts.com/${customChatbot}.js`;
            } else if (showChatbot === 4) { // chatbot.com
              chatBot.innerText = `window.__be = window.__be || {};window.__be.id = "${customChatbot}";(function() { var be = document.createElement("script"); be.type = "text\/javascript"; be.async = true; be.src = ("https:" == document.location.protocol ? "https:\/\/" : "http:\/\/") + "cdn.chatbot.com/widget/plugin.js"; var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(be, s);})();`;
            } else if (showChatbot === 5) { // landbot.io
              chatBot.innerText = `window.addEventListener("mouseover", initLandbot, { once: true }); window.addEventListener("touchstart", initLandbot, { once: true }); var myLandbot; function initLandbot() {if (!myLandbot) {var s = document.createElement("script");s.type = "text/javascript";s.async = true;s.addEventListener("load", function() {var myLandbot = new Landbot.Livechat({configUrl: "https://storage.googleapis.com/landbot.online/v3/${customChatbot}/index.json",});});s.src = "https://cdn.landbot.io/landbot-3/landbot-3.0.0.js";var x = document.getElementsByTagName("script")[0];x.parentNode.insertBefore(s, x);}}`;
            } else {
              // other
            }
          }
        }
        //console.log('chatBot', chatBot);
        document.body.appendChild(chatBot);
        // console.log('END initCommunity');
      } catch (error) {
        console.error('Error initializing community:', error);
        this.$router.push({ name: 'error', params: { error: '404' } });
      }
    },
    async getIntegrations() {
      if(this.integrations?.unpaginated?.length > 0){
        return this.integrations.unpaginated;
      }
      return await this.fetchIntegrations();
    },
    async fetchIntegrations() {
      // Ensure we have a valid slug
      if (!this.communitySlug) {
        return [];
      }
      
      // Get the main space slug, ensuring it's a string
      let mainSpaceSlug = this.mainSpace?.slug;
      if (typeof mainSpaceSlug === 'object' && mainSpaceSlug !== null) {
        console.warn('fetchIntegrations - mainSpace.slug is an object', mainSpaceSlug);
        mainSpaceSlug = mainSpaceSlug.slug || mainSpaceSlug.id || '';
      }
      
      // Convert to string and validate
      mainSpaceSlug = String(mainSpaceSlug || '');
      if (!mainSpaceSlug) {
        console.warn('fetchIntegrations - empty mainSpaceSlug');
        return [];
      }
      
      try {
        const integrations = await this.$store.$service[Service.BackendClient].get(
          'addons',
          {
            params: {
              type: 'integrations',
              communitySlug: mainSpaceSlug,
            },
          }
        );
        return integrations.data?.data || [];
      } catch (error) {
        console.error('Error fetching integrations:', error);
        return [];
      }
    },
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    handleLayoutError(error) {
      console.error('Layout error:', error);
      this.$router.push({ name: 'error', params: { error: 'layout-error' } });
    },
  },
};
</script>
